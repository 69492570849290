export default {
    validateType(val){
      if(!val) return false
      if(!(/\\\/\/\\=/.test(val))) return false // barcode from a scanner would be of the format \//\=.+
      let s = val.replace(/\\\/\/\\=/,'').split('-')[0]
      let types = ["VSID", "INV", "DN", "PO", "JT", "PL"]
      if(types.filter(x=>x===s)[0]){
        return types.filter(x=>x===s)[0]
      }
      return 'EXT'
    }
};
