<template>
  <v-app id="inspire">
    <v-main>
      <span v-if="!this.ready" style="height: 100%;" class="d-flex flex-row align-center justify-center">
        <v-progress-circular
            indeterminate
            color="green"
        ></v-progress-circular>
      </span>
      <span v-else>
        <div v-if="this.getUser" style="width: 100%; height: 60px; background-color: #777; padding: 10px; position: fixed; z-index:10;" class="d-flex flex-row justify-space-between">
          <span style="margin-top: 7px;">{{this.getGreeting()}}</span>
          <span class="d-flex flex-row align-center">
            <span>
              <v-icon class="mr-2" :color="this.printInProgress?'red':'grey'">mdi-printer</v-icon>
              <v-icon :color="this.scanInProgress?'red':'grey'">mdi-barcode-scan</v-icon>
              <v-btn class="ml-3" style="margin-top: -5px;" @click="logoutA" color="success" x-small fab><v-icon>mdi-close</v-icon></v-btn>
            </span>
          </span>
        </div>
        <v-container
          class="fill-height"
          style="margin-bottom: 50px; justify-content: flex-start; align-items: flex-start; padding: 80px 30px 30px 30px; position: relative; z-index:0;"
          fluid
        >
          <router-view :key="$route.path" />
          <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
            {{ snackObj.text }}
            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
            </template>
          </v-snackbar>
        </v-container>
      </span>
      <v-dialog v-model="logoutDialog" width="500">
        <v-card class="d-flex flex-column align-center justify-center">
          <v-card-title><h3>Confirm Logout</h3></v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="logoutB()"
            >
              Logout
            </v-btn>
            <v-btn
              color="secondary"
              text
              @click="logoutDialog = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
    <v-bottom-navigation
      v-if="this.getUser"
      :value="!!this.getUser"
      color="blue"
      fixed
      grow
      style="width: 100%;"
    >
      <v-btn v-for="item, index of items" :key="index" :to="item.path">
        <span>{{item.name}}</span>
        <v-icon>{{item.icon}}</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <div>⚡ by Varion</div>
  </v-app>
</template>
<style>
  .v-snack {
    margin-bottom: 50px;
  }
</style>
<script>
  import { mapGetters, mapMutations } from "vuex"
  import scanSystem from "./plugins/scanSystem"
  import axios from 'axios'
  export default {
    props: {
      source: String,
    },
    data: () => ({
      dialog: false,
      drawer: null,
      timeout: null,
      logoutDialog:false,
      scanInProgress: false,
      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      ready: false,
      items: [
        { icon: 'mdi-home', text: 'Home', path: '/' },
        { icon: 'mdi-currency-usd', text: 'Orders', path: '/orders' },
        { icon: 'mdi-account-group', text: 'Customers', path: '/customers' },
        // { icon: 'mdi-package', text: 'Product Manager', path: '/products' },
      ],
      branchLoader: false,
      keyShortcutMode: false,
    }),
    watch: {
      $route (to, from){
        if(to.name==="order"){
          this.lockScanBus("order")
        }
        if(from.name==="order"&& to.name!=="order"){
          this.unlockScanBus()
          this.resetScanBus()
        }
      },
      activateScanSensePing(){
        this.activateScanSense()
      }
    },
    async mounted(){
      try {
        // very important: determine what is the origin
        let origin = window.location.origin;
        if(origin == process.env.VUE_APP_CLOUD_ORIGIN){
          this.setEndpoint(process.env.VUE_APP_CLOUD_ENDPOINT)
        }else if(origin == process.env.VUE_APP_PREM_ORIGIN){
          this.setEndpoint(process.env.VUE_APP_PREM_ENDPOINT)
        }else if(origin.includes(process.env.VUE_APP_LOCALHOST_ORIGIN)){
          this.setEndpoint(process.env.VUE_APP_LOCALHOST_ENDPOINT)
        }else if(origin.includes(process.env.VUE_APP_PUBLIC_ORIGIN)){
          this.setEndpoint(process.env.VUE_APP_PUBLIC_ENDPOINT)
        }else{
          this.setEndpoint(process.env.VUE_APP_EPHEMERAL_ENDPOINT)
        }
        console.log(`Origin: ${origin} | Endpoint Set: ${this.getEndpoint}`)
        if(!this.getUser){ // the application was just reloaded, so we try to send a req to determine if the user has an active session
          let user = await axios.get(`${this.getEndpoint}/api/users/self`)
          console.log(user)
          if(!user || user.data.error || !user.data.success){
            if(this.$route.name != "Login")
                await this.$router.push({path: "/login"}) // there was an error so redirect to login page
          }else{
            if(user.data.success){ // the user has an active session so we 
              this.setUser(user.data.data)
              console.log("User set back.")    
      
              let userNames = await axios.get(`${this.getEndpoint}/api/users/userNames`)
              if(userNames.data.error) console.log("APP.JS❌ User Cache Data Not Fetched.")
              else this.setUserCache(userNames.data.data)
      
              let branches = await axios.get(`${this.getEndpoint}/api/locations/branches`)
              if(branches.data.error) console.log("APP.JS❌ Branch Data Not Fetched.")
              else this.setBranches(branches.data.data);

              if(this.paymentMethods.length<1){
                let paymentMethods = await axios.get(`${this.getEndpoint}/api/paymentmethods/lite`)
                if(!paymentMethods.data.error) this.setPaymentMethods(paymentMethods.data.data)
              }
  
              //Barcode and Shortcut Daemon
              this.activateScanSense()
            }
          }
        }
      } catch (error) {
        console.log(error)
        if(this.$route.name !== "Login")
          await this.$router.push({path: "/login"})
      } finally {
        this.ready = true;
      }
    },
    computed: {
      ...mapGetters([
        'globalQueryBcAllowed',
        'getEndpoint',
        'isAllowed',
        'getUser',
        'getId',
        'scanBus',
        'lookupUsername',
        'lookupBranch',
        'getAllowedBranches',
        'getBranch',
        'printInProgress',
        'paymentMethods',
        'activateScanSensePing'
      ])
    },
    methods: {
      ...mapMutations([
        'logout',
        'setUser',
        'setEndpoint',
        'setUserCache',
        'lockGlobalQueryBc',
        'unlockGlobalQueryBc',
        'lockScanBus',
        'unlockScanBus',
        'setScanBusData',
        'setScanBusType',
        'resetScanBus',
        'setBranches',
        'setPrintProgress',
        'setPaymentMethods'
      ]),
      logoutA(){
        this.logoutDialog = true
      },
      logoutB(){
        console.log("Logout")
        this.logoutDialog = false
        this.$router.push({path: "/logout"})
      },
      activateScanSense(){
        console.log('APP.JS: ✅ Scan Sense Active.')
        window.addEventListener('keypress', (e)=>{ // keys will always be pressed,
          if(this.getUser && !this.scanBus.locked){ //to use barcode services an invoker must be logged in and the scanbus cannot be locked
            this.cancelClearScanStringTimeout()
            this.clearScanStringTimeout()
            if(this.keyShortcutMode){
              this.cancelClearScanStringTimeout()
              if(e.key==='/'){
                this.endShortcut()
              }else{
                if(e.key==='Enter'){
                  console.log(this.scanString)
                  this.handleShortcut(this.scanString)
                }else{
                  if(!this.scanString) this.scanString = ""
                  this.scanString = this.scanString + e.key
                }
              }
            }else{
              if(e.key==='/'){
                if(!this.scanString){
                  this.cancelClearScanStringTimeout()
                  this.keyShortcutMode = true
                }else{
                  this.scanString = this.scanString + e.key
                }
              }else{
                if(e.key==='Enter'){
                  let validatedType = scanSystem.validateType(this.scanString)
                  if(validatedType){
                    this.setScanBusType(validatedType)
                    this.scanInProgress = true
                    this.handleScan(this.scanBus.type)
                    this.clearScanString
                  }else{
                    this.clearScanString
                    this.resetScanBus()
                  }
                }else{
                  this.scanInProgress = false
                  if(!this.scanString) this.scanString = ""
                  this.scanString = this.scanString + e.key
                }
              }
            }
          }
        })  
      },
      getGreeting(){
        if(this.getUser != null && this.getUser != undefined){
          let d = new Date().getHours()
          let h = ''
          if(d >= 0 && d < 11) h = 'Morning'
          if(d >= 11 && d < 14) h = 'Day'
          if(d >= 14 && d < 17) h = 'Afternoon'
          if(d >= 17 && d < 19) h = 'Evening'
          if(d >= 19 && d <= 23) h = 'Night'
          return 'Good ' + h + ', ' + this.getUser.firstName+"!"
        }
      },
      clearScanString(){
        this.scanString = ''
        this.scanInProgress = false
        console.log("🔁 Scan String Cleared")
      },
      clearScanStringTimeout(){
        this.timeout = setTimeout(this.clearScanString,500)
      },
      cancelClearScanStringTimeout(){
        clearTimeout(this.timeout)
      },
      async handleScan(type){
        try {
          let s = this.scanString.replace(/\\\/\/\\=/,'')
          let pId = (s.replace(`${type}-`, ''));
          let p;
          pId
          let prod = null
          switch(type){
            case 'INV':
              console.log("Invoice Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'VSID':
              // this.setScanBusData({username: this.lookupUsername(pId.split("-")[0]), uId: pId.split("-")[0]})
              // console.log(this.lookupUsername(pId.split("-")[0]))
              p = '/users/view/'+pId.split("-")[0]
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'JT':
              console.log("Job Ticket Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'DN':
              console.log("Delivery Note Scanned: ", s)
              p = `/deliveries/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'PL':
              console.log("Product Scanned: ", s)
              p = `/products/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'EXT':
              console.log("External Product Scanned: ", s)
              prod = await this.lookupProduct(s)
              if(!prod) throw "External Product Not In VIMS DB."
              p = `/products/view/${prod.id}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
          }
        } catch (error) {
          console.log(error)
          this.scanInProgress = false
          this.snack(error)
        }
      },
      endShortcut(){
        this.clearScanString()
        this.keyShortcutMode = false
      },
      async handleShortcut(code){
        try {
          switch(true){
            case /lo/.test(code):
              console.log("Logout Shortcut: ", this.scanString)
              this.endShortcut()
              this.logoutB()
              break
            case /o\d+/.test(code):
              console.log("Jump To An Order: ", this.scanString)
              await this.$router.push({path: `/`})
              await this.$router.push({path: `/orders/view/${this.scanString.split("o")[1]}`})
              this.endShortcut()
              break
            case /op/.test(code):
              console.log("Jump To An Order and print: ", this.scanString)
              await this.$router.push({path: `/orders/view/${this.scanString.split("o")[1]}`})
              this.endShortcut()
              break
            case (/o/.test(code)&&code.length==1):
              console.log("Jump To Order Index: ", this.scanString)
              await this.$router.push({path: `/orders`})
              this.endShortcut()
              break
            case /c\d+/.test(code):
              console.log("Jump To Customer: ", this.scanString)
              await this.$router.push({path: `/customers/view/${this.scanString.split("c")[1]}`})
              this.endShortcut()
              break
            case /c/.test(code):
              console.log("Jump To Customer Index: ", this.scanString)
              await this.$router.push({path: `/customers`})
              this.endShortcut()
              break
            case /u\d+/.test(code):
              console.log("Jump To A User: ", this.scanString)
              await this.$router.push({path: `/users/view/${this.scanString.split("u")[1]}`})
              this.endShortcut()
              break
            case /u/.test(code):
              console.log("Jump To User Index: ", this.scanString)
              await this.$router.push({path: `/users`})
              this.endShortcut()
              break
            case /po\d+/.test(code):
              console.log("Jump To A PO: ", this.scanString)
              await this.$router.push({path: `/purchaseorders/view/${this.scanString.split("po")[1]}`})
              this.endShortcut()
              break
            case /pos\d+/.test(code):
              console.log("Jump To Purchase Orders by Supplier: ", this.scanString)
              await this.$router.push({path: `/`})
              await this.$router.push({path: `/purchasing?supplier=${this.scanString.split("pos")[1]}`})
              this.endShortcut()
              break
            case /po/.test(code):
              console.log("Jump To PO Index: ", this.scanString)
              await this.$router.push({path: `/purchasing`})
              this.endShortcut()
              break
            case /ps\d+/.test(code):
              console.log("Jump To Products By Supplier: ", this.scanString)
              await this.$router.push({path: `/`})
              await this.$router.push({path: `/products?supplier=${this.scanString.split("ps")[1]}`})
              this.endShortcut()
              break
            case /p\d+/.test(code):
              console.log("Jump To A Product: ", this.scanString)
              await this.$router.push({path: `/`})
              await this.$router.push({path: `/products/view/${this.scanString.split("p")[1]}`})
              this.endShortcut()
              break
            case /p/.test(code):
              console.log("Jump To Product Index: ", this.scanString)
              await this.$router.push({path: `/products`})
              this.endShortcut()
              break
            case /rr\d+/.test(code):
              console.log("Jump To A RR: ", this.scanString)
              await this.$router.push({path: `/receivingreports/view/${this.scanString.split("rr")[1]}`})
              this.endShortcut()
              break
            case /s\d+/.test(code):
              console.log("Jump To A Supplier: ", this.scanString)
              await this.$router.push({path: `/suppliers/view/${this.scanString.split("s")[1]}`})
              this.endShortcut()
              break
            case /s/.test(code):
              console.log("Jump To Supplier Index: ", this.scanString)
              await this.$router.push({path: `/suppliers`})
              this.endShortcut()
              break
            case /d\d+/.test(code):
              console.log("Jump To A Device: ", this.scanString)
              await this.$router.push({path: `/devices/view/${this.scanString.split("d")[1]}`})
              this.endShortcut()
              break
            case /d/.test(code):
              console.log("Jump To Devices Index: ", this.scanString)
              await this.$router.push({path: `/devices`})
              this.endShortcut()
              break
            case /b/.test(code):
              console.log("Jump Back 1: ")
              await this.$router.go(-1)
              this.endShortcut()
              break
            case /va/.test(code):
              console.log("SUPERADMIN")
              await this.$router.push({path: `/superadmin`})
              this.endShortcut()
              break
            default:
              this.endShortcut()
          }
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      async updateBranch(data){
        try{
          this.branchLoader = true;
          let res = await axios.put(`${this.getEndpoint}/api/users/updateMainBranch/${this.getId}`, {branch: data})
          if(res.data.error) throw res.data.error

          this.setUser(res.data.data)
          this.snack("Branch Updated");
          await this.$router.push({path: `/`})
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.branchLoader = false;
        }
      },
      async lookupProduct(s){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
          if(res.data.error) throw res.data.error
          if(!res.data.data) throw 'External barcode is not in VIMS DB.'
          return res.data.data
        } catch (error) {
          if(error.customError){
            console.error(error)
            this.snack('External barcode is not in VIMS DB.')
          }
          else this.snack(error)
        }
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      }
    }
  }
</script>