let viewFolder = "Container";

module.exports = [
    {
        path: '/warehouses/view/:warehouseId/rooms/view/:roomId/containers/view/:id',
        name: 'container',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/View.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    }
]