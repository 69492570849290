export default {
    parseStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
    },
    getStatusColor(s){
        let color = 'grey'
        if(s===-1) color = 'warning'
        if(s===0) color = 'info'
        if(s===1) color = 'success'
        return color
    },
    calculateMarkup(c,s){
        if(!c || !s || c == 0 || s == 0) return 0
        let val = parseFloat(100 * ((s-c)/c)).toFixed(2) + "%"
        return val 
    },
    formatPrice(value){
        if(value===null) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
    },
}