let mainPath = "/warehouses";
let viewFolder = "Warehouse";

module.exports = [
    {
        path: mainPath,
        name: 'warehouses',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Index.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    },
    {
        path: mainPath+'/view/:id',
        name: 'warehouse',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/View.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    }
]