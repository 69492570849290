let frontendAuth = true

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Warehouses from './warehouses';
import Rooms from './rooms';
import Containers from './containers';
import Customers from "./customers";
import Branches from "./branches";
import UserTypes from "./userTypes";
import Users from "./users";
import PurchaseOrders from "./purchaseOrders";
import Suppiers from "./suppliers";
import Orders from "./orders";
import OrderTypes from "./orderTypes";
import PaymentTypes from "./paymentTypes";
import ProductAttributes from "./productAttributes";
import ProductAttributeTerms from "./productAttributeTerms";
import ProductTags from "./productTags";
import ProductCategories from "./productCategories";
import Products from "./products";
import Sync from "./sync";
import Brand from "./brands";
import Device from "./devices";
import ReceivingReport from "./receivingReports";
import Delivery from "./deliveries";

Vue.use(VueRouter)

let routes = []
let defaultRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },  
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
  },
  {
    path: '/superadmin',
    name: 'Super Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuperAdmin.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/Logout.vue'),
    // beforeEnter: (to, from, next) => {
    //   if (!localStorage.getItem('id')) next()
    //   else next({name: from.name})
    // }
  }
]

routes = routes.concat(
    defaultRoutes,
    Warehouses,
    Rooms,
    Containers,
    Customers,
    Branches,
    UserTypes,
    Users,
    Suppiers,
    OrderTypes,
    PaymentTypes,
    ProductAttributes,
    ProductAttributeTerms,
    ProductTags,
    ProductCategories,
    Products,
    PurchaseOrders,
    Sync,
    Brand,
    Device,
    Orders,
    ReceivingReport,
    Delivery
);

const router = new VueRouter({
  routes
})

if(frontendAuth){
  // router.beforeEach((to, from, next) => {
  //   if (to.path !== '/login') next({ name: 'Login' })
  //   else next()
  // })
}


export default router
