<template>
  <v-container fluid>
    <v-progress-circular
        class="d-flex flex-row justify-center align center"
        indeterminate
        color="green"
        v-if="loader"
        style="margin-left: 10px; width: 100%;"
    ></v-progress-circular>
    <span v-else>
      <v-row>
        <v-col class="d-flex flex-row justify-center">
          <h1>Teelucksingh's CSR</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-title><h3>Quick Actions</h3></v-card-title>
            <v-card-text>
              <v-btn block @click="attemptCreateOrderA()" class="mb-2" color="info"><v-icon left>mdi-plus</v-icon> Create Order</v-btn>
              <v-btn block to="/customers" class="mb-2" color="info"><v-icon left>mdi-account-group</v-icon> Customers</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-title><h3>Search</h3></v-card-title>
            <v-card-text>
              <v-radio-group style="margin-top: -10px;" row v-model="search.type" @change="clearSearch">
                <v-radio
                  v-for="option in searchOptions"
                  :key="option"
                  :label="option"
                  :value="option"
                ></v-radio>
              </v-radio-group>
              <span class="d-flex flex-row align-center">
                <v-text-field dense ref="search" v-model="search.term" @change="attemptSearch" :loading="search.loader" label="Search" outlined></v-text-field>
                <v-btn @click="attemptSearch" class="mb-6 ml-3" fab x-small color="success">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </span>
              <span v-if="search.noSearchResults" class="d-flex flex-row align-center mb-3">
                <v-btn @click="clearSearch" fab x-small color="error"><v-icon>mdi-close</v-icon></v-btn>
                <h3 class='mb-2 ml-3'><b>Found no results for this search.</b></h3>
              </span>
              <span v-if='search.filteredResults.length>0'>
                <span class="d-flex flex-row align-center mt-3 mb-3">
                  <h3 class='mb-2 mr-3'>Found {{search.results.length}} {{search.type}} matching <q>{{search.term}}</q>: </h3>
                  <v-btn @click="clearSearch" fab x-small color="error"><v-icon>mdi-close</v-icon></v-btn>
                </span>
                <span>Refine By:</span>
                <span class="d-flex flex-row flex-wrap">
                  <span class="mr-1 mb-5" v-for="(item, index) in this.searchResultsRefineBy" :key='index'>
                    <v-chip :outlined="!item.selected" :color="item.color" @click="()=>{refineResults(item);}">{{item.name}}</v-chip>
                  </span>
                </span>
                <span v-if="search.type=='Products'">
                  <v-card outlined :key="i" v-for="(product, i) in search.filteredResults" class="mb-2 d-flex flex-row align-center justify-space-between">
                    <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
                      <h3>{{product.name}}</h3>
                      <span>Type: {{product.type.toUpperCase()}}</span>
                      <span>Brand: {{product.Brand?product.Brand.name:'-'}}</span>
                      <span>SKU: {{product.sku?product.sku:'-'}}</span>
                      <span>Reg: ${{product.regularPrice?product.regularPrice:'-'}} | Sale: ${{product.salePrice?product.salePrice:'-'}}</span>
                      <p v-if="product.Suppliers && product.Suppliers.length && isAllowed('product','viewCostPrice') > 0 && product.Suppliers[0].ProductSupplierJoin && product.Suppliers[0].ProductSupplierJoin.costPrice">Cost Price ${{product.Suppliers[0].ProductSupplierJoin.costPrice}} (+{{utils.calculateMarkup(product.Suppliers[0].ProductSupplierJoin.costPrice,product.regularPrice)}})</p>
                      <span>Qty: {{product.ProductLocationJoins&&product.ProductLocationJoins.length>0?product.ProductLocationJoins.reduce((acc,x)=>acc+parseInt(x.available),0):0}}</span>
                    </span>
                    <v-btn class="mr-2" x-small fab color="success" @click="()=>{$router.push({path: '/products/view/'+product.id})}"><v-icon>mdi-chevron-right</v-icon></v-btn>
                  </v-card>
                </span>
                <span v-if="search.type=='Customers'">
                  <v-card outlined :key="i" v-for="(customer, i) in search.results" class="mb-2 d-flex flex-row align-center justify-space-between">
                    <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
                      <h3>{{customer.name}}</h3>
                      <p v-if="customer.email">{{customer.email}}</p>
                      <p v-if="customer.phone">{{customer.phone}}</p>
                    </span>
                    <v-btn class="mr-2" x-small fab color="success" @click="()=>{$router.push({path: '/customers/view/'+customer.id})}"><v-icon>mdi-chevron-right</v-icon></v-btn>
                  </v-card>
                </span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </span>
    <v-dialog
      v-model="createOrderDialog.isOpen"
      width="600"
      fixed
    >
      <v-card class="text-h5 d-flex flex-column align-center justify-center">
        <v-card-title>
          <b>Confirm Create Order</b>
        </v-card-title>
        <v-card-actions>
          <v-btn color="success" text @click="attemptCreateOrderB()">Confirm</v-btn>
          <v-btn color="secondary" text @click="closeCreateOrderDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios';
import utils from '../plugins/helpers'
export default {
  name: 'Home',
  data () {
    return {
      utils: utils,
      loader: true,
      searchOptions: ['Products', 'Customers'],
      createOrderDialog: {
        isOpen: false,
      },
      search: {
        type: '',
        term: '',
        loader: false,
        results: [],
        filteredResults: [],
        noSearchResults: false,
      },
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getEndpoint', 'getId', 'isAllowed']),
    searchResultsRefineBy(){
      if(this.search.type == "Products"){        
        let y = this.search.results
        let uBrands = [], uSuppliers = []
        y.forEach(item=>{
          if(item.Brand){
            let t = item.Brand
            if((uBrands.filter(val=>val.id==t.id)).length < 1){
              t.color = 'green'
              t.selected = false
              uBrands.push(t)
            }
          }else {
            console.log(item)
            let bg = item.Suppliers
            if(bg){
              bg.forEach(t=>{
                console.log(t)
                if((uSuppliers.filter(val=>val.id==t.id)).length < 1){
                  t.color = 'orange'
                  t.selected = false
                  uSuppliers.push(t)
                }
              })
            }
          }
        })
        return uBrands.concat(uSuppliers)
      }
      return false
    },
  },
  async mounted(){
    this.search.type = this.searchOptions[0]
    this.loader = false
  },
  methods: {
    attemptCreateOrderA(){
      this.createOrderDialog.isOpen = true
    },
    closeCreateOrderDialog(){
      this.createOrderDialog.isOpen = false
    },
    async attemptCreateOrderB(){
      try {
        let res = await axios.post(`${this.getEndpoint}/api/orders`, {createdBy: this.getId})
        if(res.data.error) throw res.data.error
        console.log(res.data)
        await this.$router.push({path: `/orders/view/${res.data.data.id}`})
      } catch (error) {
        console.log(error)
      }
    },
    attemptSearch(){
      if(this.search.type==="Products"){
        this.searchProduct()
      }
      else if(this.search.type==="Customers"){
        this.searchCustomer()
      }
    },
    async searchCustomer(){
      try {
        if(this.search.term){
          this.search.loader = true
          let res = await axios.get(`${this.getEndpoint}/api/customers/search?val=${encodeURIComponent(this.search.term)}`);
          if(res.data.error) throw res.data.error
          this.search.results = res.data.data
          this.search.filteredResults = this.search.results 
          if(this.search.results && this.search.results.length == 0) this.search.noSearchResults = true
          else this.search.noSearchResults = false
        }
      } catch (error) {
        console.log(error)
        this.snack(error)
      }finally{
        this.search.loader = false
      }
    },
    async searchProduct(){
      try {
        if(this.search.term){
          this.search.loader = true
          let res = await axios.get(`${this.getEndpoint}/api/products/searchByAll?val=${encodeURIComponent(this.search.term)}`);
          if(res.data.error) throw res.data.error
          this.search.results = res.data.data
          this.search.filteredResults = this.search.results 
          if(this.search.results && this.search.results.length == 0) this.search.noSearchResults = true
          else this.search.noSearchResults = false
        }
      } catch (error) {
        console.log(error)
        this.snack(error)
      }finally{
        this.search.loader = false
      }
    },
    clearSearch(){
      this.search.term = ''
      this.search.results = []
      this.search.filteredResults = []
      this.search.noSearchResults = false
      this.$refs.search.focus();
    },
    refineResults(item){
      this.search.filteredResults = this.search.results
      if(item.color=="green"){
        this.search.filteredResults = this.search.filteredResults.filter(x=>x.Brand&&x.Brand.id==item.id)
      }else{
        this.search.filteredResults = this.search.filteredResults.filter(x=>(x.Suppliers.filter(y=>y.id==x.id).length<1)&&!x.Brand)
      }
      this.searchResultsRefineBy.forEach(p=>p.selected = false)
      item.selected = true
    },
    clearFilter(){
      this.search.filteredResults = this.search.results
    }
  }
}
</script>
