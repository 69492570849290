import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    scanTypes: ["VSID"],
    user: null,
    allowedBranches: [],
    endpoint: null,
    userCache: [],
    branches: [],
    scanBus: {
      data: null,
      type: null,
      locked: false,
    },
    printInProgress: false,
    defaults: {
      logoPath: '/images/logo.png',
      appName: "Teelucksingh's ERP",
      appSubName: "CSR Interface",
      appVersion: "1.0.0"
    },
    paymentMethods: [],
    activateScanSensePing: false,
  },
  mutations: {
    setUser(state, user){
      state.user = user
      state.allowedBranches = state.user.metadata.branchData.allowed.map(x => {
        return state.branches.find(n => x===n.id);
      });
    },
    setUserCache(state, users){
      state.userCache = users
      console.log(state.userCache.length > 0 ? "VUEX: ✅ User Cache loaded." : "VUEX: ❌ User Cache not loaded.")
    },
    setBranches(state, branches){
      state.branches = branches
      console.log(state.branches ? "VUEX: ✅ Branches loaded." : "VUEX: ❌ Branches not loaded.")
    },
    setBranch(state, branch){
      state.branch = branch
    },
    setPaymentMethods(state, paymentMethods){
      state.paymentMethods = paymentMethods
      console.log(state.paymentMethods.length > 0 ? "VUEX: ✅ Payment Methods Cache loaded." : "VUEX: ❌ Payment Methods Cache not loaded.")
    },
    clearUserCache(state){
      state.userCache = []
    },
    setEndpoint(state, endpoint){
      state.endpoint = endpoint;
    },
    initialiseStore() {
      console.log("✅ Vuex Initialized.")
    },
    logout(state){
      state.user = null
    },
    setScanBusData(state, data){
      if(data){
        state.scanBus.data = data
      }
    },
    setScanBusType(state, type){
      if(type)
        state.scanBus.type = type
    },
    resetScanBus(state){
      state.scanBus.type = null
      state.scanBus.data = null
    },
    lockScanBus(state,type){
      state.scanBus.locked = true
      state.scanBus.type = type
      console.log("Scan Bus Locked")
    },
    unlockScanBus(state, prev=null){
      if(prev){
        state.scanBus=prev
        console.log("Scan Bus Returned to Previous State")
      }else{
        state.scanBus.locked = false
        state.scanBus.type = null
        console.log("Scan Bus Unlocked")
      }
    },
    setPrintInProgress(state, value){
      state.printInProgress = value
    },
    activateScanSense(state){ 
      state.activateScanSensePing = true
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getId: state => state.id,
    getEndpoint: state => state.endpoint,
    getUserCache: state => state.userCache,
    lookupUsername: (state) => (id) =>{
      if(!id) return null
      let y = state.userCache.find(x=>x.id==id)
      if(!y) return null
      return y.firstName + ' ' + (y.lastName?y.lastName:'')
    },
    getBranch: state => state.user.metadata.branchData.main,
    getBranches: state => state.branches,
    getAllowedBranches: state => state.allowedBranches,
    lookupBranch: (state) => (id) =>{
      if(!id) return null
      let y = state.branches.find(x=>x.id==id)
      if(!y) return null
      return y.name;
    },
    getUser: state => state.user,
    isAllowed: (state) => (resource, requiredPerm) => {
      if(!resource) return false
      if(!requiredPerm) return false
      if(!state.user.perms) return false
      return (state.user.perms.permissions[resource])[requiredPerm]
    },
    nowServing: state => state.sIDAuthorizer.nowServing,
    scanBus: state => state.scanBus,
    printInProgress: state => state.printInProgress,
    defaults: state => state.defaults,
    paymentMethods: state => state.paymentMethods,
    activateScanSensePing: state => state.activateScanSensePing,
  }
})
