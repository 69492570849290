import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'

axios.defaults.withCredentials = true;

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err){
  if(err.response.status == 401){
      localStorage.removeItem('id')
      router.push({path: '/login'})
  }
})

Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  beforeCreate() { this.$store.commit('initialiseStore');},
  render: h => h(App)
}).$mount('#app')
